import React from "react";
import { useHistory } from "react-router-dom";
import Img from "../recipe_component/recipeImg";
import Heading from "../recipe_component/recipeHeading";
import Clock from "../../menuRecipePage/menuRecipeHeader/menuRecipeHeaderAsset/Clock";
import "./card-style.css";

const RecipeCard = (props) => {
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    history.push(`/product/${props._id}`);
  };
  return (
    <div className="recipeCard_outta">
      <div className="card text-center recipeCard_div">
        <a
          // href="https://www.blueapron.com/recipes/greek-style-veggie-pitas-with-lemon-dressed-broccoli"
          onClick={handleClick}
          className="recipeCard_img"
        >
          <div className="overflow">
            <Img className="card-img-top " image={props.image}/>
          </div>
          <div className="card-body text-dark recipeCard_body">
            <h2 className="card-title">
              <Heading name={props.name}/>
            </h2>
            <h4 className="card-text text-secondary">
              {/* with Lemon-Dressed Broccoli */}
              {props.description}
            </h4>
            <br></br>
            <p>
              <Clock width={20} fill={"#6A9A1D"} />
              {/* 25min */}
              &nbsp;{props.countInStock}
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default RecipeCard;
