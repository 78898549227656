import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { detailsProduct } from '../Redux/Actions/productActions';
import Rating from '../Components/Rating'

const ProductScreen = (props) => {
    const [qty, setQty] = useState(1);
    const productDetails = useSelector(state => state.productDetails);
    const { product, loading, error } = productDetails;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(detailsProduct(props.match.params.id));
        return () => {
            // cleanup
        };
    }, [])

    const handleAddtoCart = () => {
        props.history.push("/cart/" + props.match.params.id + "?qty=" + qty)
    }

    const handleBack = (e) => {
        e.preventDefault();
        props.history.goBack()
    }

    // console.log(props.match.params.id)
    // const product = data.products.find(x=> x._id ===props.match.params.id)
    return (
        <div >
            <div className="back-to-result">
                <Link onClick={handleBack} to="/shop">Back to result</Link>
            </div>
            {loading ? <div>Loading</div> :
                error ? <div>{error}</div> :
                    (
                        <div className="details">
                            <div className="details-image">
                                <img src={product.image} alt="product"></img>
                            </div>
                            <div className="details-info">
                                <ul>
                                    <li>
                                        <h4>{product.name}</h4>
                                    </li>
                                    <li>
                                        <h4>Type:{product.type}</h4>
                                    </li>
                                    <li>
                                        <h4>Category:{product.category}</h4>
                                    </li>
                                    <li>
                                        <h4>Serving: For {product.type}</h4>
                                    </li>
                                    {/* <li>
                                        <h4>{product.brand}</h4>
                                    </li> */}
                                    <li>
                                        <h4>Standard Price:{product.price}</h4>
                                    </li>
                                    <li>
                                        <h4>Cooking Difficulties:{product.difficulty}</h4>
                                    </li>
                                    <li>
                                        <h4>Cooking Time:{product.c_time}</h4>
                                    </li>
                                    <li>
                                        <h4>Calories:{product.calories}</h4>
                                    </li>
                                    {/* <li>
                                        <h4>Bundle Price:{product.bprice}</h4>
                                    </li> */}
                                    <li>
                                        <h4>Description:
                                        <div>{product.description}</div></h4>
                                    </li>
                                    <li>
                            <a href="#reviews">
                                <Rating value={product.rating} text={product.numReviews + 'reviews'} />
                                </a>
                        </li>
                                </ul>
                            </div>
                            <div className="details-action">
                                <ul>
                                    <li>
                                        Standard Price:{product.price}
                                    </li>
                                    {/* <li>
                                        Bundle Price:{product.price}
                                    </li> */}
                                    <li>
                                        Status:{' '}
                                        {product.countInStock > 0 ? 'In Stock' : 'Unavailable.'}
                                    </li>

                                    <li>Qty:
                                        <select value={qty} onChange={(e) => { setQty(e.target.value) }}>
                                            {[...Array(product.countInStock).keys()].map(x =>
                                                <option key={x + 1} value={x + 1}>{x + 1}</option>
                                            )}
                                        </select>
                                    </li>
                                    <li>
                                        {product.countInStock > 0 && (
                                            <button
                                                onClick={handleAddtoCart}
                                                className="button primary"
                                            >
                                                Add to Cart
                                            </button>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
            }



        </div>);
}
export default ProductScreen;