import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listOrders, deleteOrder } from "../Redux/Actions/OrderActions";
import { Button } from "react-bootstrap";

const OrdersScreen = (props) => {
  const orderList = useSelector((state) => state.orderList);
  const { loading, orders, error } = orderList;

  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = orderDelete;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listOrders());
    return () => {
      // cleanup
    };
  }, [successDelete]);

  const deleteHandler = (order) => {
    dispatch(deleteOrder(order._id));
  };
  
  if (orders) {
        for (let i = 0; i < orders.length; i++) {
            orders[i].createdAt = new Date(orders[i].createdAt).toLocaleString();
            if (orders[i].isPaid) {orders[i].paidAt = new Date(orders[i].paidAt).toLocaleString();};
            if (orders[i].isDelivered) {orders[i].deliveredAt = new Date(orders[i].deliveredAt).toLocaleString();};
        };
   };

  return (
    <div className="content content-margined">
      <div className="order-header">
        <h3>Orders</h3>
      </div>
      <div className="order-list">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>USER</th>
              <th>PAID</th>
              <th>PAID AT</th>
              <th>DELIVERED</th>
              <th>DELIVERED AT</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody className="orderTbody">
            {orders ? (
              orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.createdAt}</td>
                  <td>{order.totalprice}</td>
                  <td>{order.user.name}</td>
                  <td>{order.isPaid.toString()}</td>
                  <td>{order.paidAt}</td>
                  <td>{order.isDelivered}</td>
                  <td>{order.deliveredAt}</td>
                  <td>
                    <Button className="order_btn__main">
                      <Link to={"/order/" + order._id} type="button">
                        Details
                      </Link>
                    </Button>
                    <Button
                      onClick={() => deleteHandler(order)}
                      className="order_btn__secondary"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OrdersScreen;
