import React from "react";
import { Card, CardHeader, CardTitle, CardBody, CardText } from "reactstrap";


import "./MenuChoice.css";

class MenuChoice extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    let center = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    };

    return (
      <div>
        <a type="button" className={`${this.props.borderBottom ? "borderBottomPlus" : ""} borderBottom`} onClick={this.props.toggleTextDiv}>
          <div className="card_div">
            <Card className="card card__style">
              <CardTitle style={center} className="cardTitle_style">
                <h2>{this.props.serving}</h2>
              </CardTitle>
              <CardHeader style={center} className="cardHeader_style">
                <img
                  className="img_style"
                  src={this.props.img}
                  alt="choice pic"
                />
              </CardHeader>

              <CardBody style={center}>
                <CardText className="cardText__style">
                  {" "}
                  <p> {this.props.title} </p>{" "}
                </CardText>
              </CardBody>
            </Card>
          </div>
        </a>
        
        
      </div>
    );
  }
}


export default MenuChoice;

