import React, { Component, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Reactstrap from "reactstrap";
import ScrollToTop from "react-router-scroll-top";
// above make the Router, Link & Switch in App.js
import Toolbar from "./Toolbar/Toolbar/Toolbar";
import SideDrawer from "./Toolbar/SideDrawer/SideDrawer";
import Backdrop from "./Toolbar/Backdrop/Backdrop";
// above Toolbar(Navbar)&Sidebar components
import Footer from "./HomePageComponent/Footer/Footer";
//above Homepage components

import HelpCenter_innerContent from "./HomePageComponent/Footer/Footer_Links/FooterLink_HelpCenter/HelpCenter_innerContent";
// <FooterLink_delivery/>
// import MenuRecipe from "./menuRecipePage/menuRecipeHeader/menuRecipeHeader";
//Above for Demo show on App

import HomePageScreen from "./screens/HomePageScreen/HomePageScreen";
import MenuPageScreen from "./screens/MenuPageScreen/MenuPageScreen";
import FooterLink_delivery from "./HomePageComponent/Footer/Footer_Links/FooterLink_delivery";
import FooterLink_aboutUs from "./HomePageComponent/Footer/Footer_Links/FooterLink_aboutUs";
import FooterLink_helpCenter from "./HomePageComponent/Footer/Footer_Links/FooterLink_HelpCenter/HelpCenter_innerContent";
import FooterLink_specialOffer from "./HomePageComponent/Footer/Footer_Links/FooterLink_specialOffer";

// import "bootstrap/dist/css/bootstrap.min.css";
// added bootstrap
import "./App.css";

import { useSelector, useDispatch } from "react-redux";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import SigninScreen from "./screens/SigninScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProductsScreen from "./screens/ProductsScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import OrdersScreen from "./screens/OrdersScreen";
import PhoneScreen from "./screens/PhoneScreen";
import JoinChat from "./Components/Join/JoinChat";
import Chat from "./Components/Chat/Chat";
import Email from "./Components/Email/Email";
import Tagsinput from "./Components/Tagsinput/Tagsinput";
import AreaSelect from "./Components/AreaSelect/AreaSelect";

const App = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  // const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  // const drawerToggleClickHandler = () => {
  //   if (sideDrawerOpen) {
  //     setSideDrawerOpen(false);
  //   } else {
  //     setSideDrawerOpen(true);
  //   }
  // };
  // const backdropClickHandler = () => {
  //   setSideDrawerOpen(false);
  // };
  // const [backdrop, setBackdrop] = useState();

  const openMenu = () => {
    document.querySelector(".sidebar").classList.add("open");
  };
  const closeMenu = () => {
    document.querySelector(".sidebar").classList.remove("open");
  };

  return (
    <Router>
      <ScrollToTop>
        <Toolbar />
        <>
      <Route path="/area" component={AreaSelect} />
          <Route path="/tags" component={Tagsinput} />
          <Route path="/email" component={Email} />
          <Route path="/joinchat" component={JoinChat} />
          <Route path="/chat" component={Chat} />
          <Route path="/orders" component={OrdersScreen} />
          <Route path="/profile" component={ProfileScreen} />
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/verify" component={PhoneScreen} />
          <Route path="/products" component={ProductsScreen} />
          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/signin" component={SigninScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/product/:id" component={ProductScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/category/:id" component={HomeScreen} />
          <Route path="/shop" component={HomeScreen} />
          <Route path="/" exact={true} component={HomePageScreen} />
          <Route path="/OnTheMenu" exact={true} component={MenuPageScreen} />
          <Route exact path="/HowWeWork"><FooterLink_aboutUs /></Route>
          {/* Above: Footer Link's pages  */}
          <Route exact path="/Delivery"><FooterLink_delivery /></Route>
          <Route exact path="/specialOffer"><FooterLink_specialOffer /></Route>
          <Route exact path="/helpCenter"><FooterLink_helpCenter /></Route>
        </>
        <Footer />

      </ScrollToTop>
    </Router>
  );
};
export default App;
