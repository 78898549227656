import React from "react";
import { useHistory } from "react-router-dom";
import "./Discount.css";
import gift from "./DiscountAsset/gift.png";

const Discount = () => {
  const history = useHistory();
  const handlePlan = (e) => {
    e.preventDefault();
    history.push("/OnTheMenu");
  };
  return (
    <div className="discountBox__outta">
      <div className="discountBox__inner">
        <img src={gift} alt="gift img" className="img-thumbnail"/>
        <p>
          Starting at $100 per serving
          <br />
          No commitment. Skipping or canceling meals is easy.
        </p>
        <a //href=""
          type="button"
          className="discount__btn"
          onClick={(e)=>handlePlan(e)}
        >
          <p>Choose your plan today</p>
        </a>
      </div>
    </div>
  );
};
export default Discount;
