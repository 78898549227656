import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../Banner/Banner.css";

const Banner = (props) => {
  const history = useHistory();
  const handleStart = (e) => {
    e.preventDefault();
    history.push("/shop");
  };
  return (
    <div className="bannerbox__outta">
      <div className="bannerboxInner_opacity">
        <div className="banner__content1 ">
          <h1>Order our top-rated recipes today!</h1>
          <a className="banner__btn" type="button" onClick={(e)=>handleStart(e)}>
            <p>get started</p>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Banner;
