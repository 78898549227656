import React from "react";

import Banner from "../../HomePageComponent/Banner/Banner";
import Discount from "../../HomePageComponent/Discount/Discount";
import OrderStep from "../../HomePageComponent/OrderStep/OrderStep";
import WhyUs from "../../HomePageComponent/WhyUs/whyUs";
// above is all the Homepage's component

// import ScrollUpButton from "../../ScrollUpBtn/scrollUpBtn";


import "./HomePageScreen.css";

const HomePageScreen = (props) => {
  return (
    <div className="homePageScreen">
      <Banner />
      <WhyUs />
      <OrderStep />
      <Discount />
    </div>
  );
};
export default HomePageScreen;
