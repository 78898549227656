import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { savePayment } from "../Redux/Actions/CartActions";
import CheckoutSteps from "../Components/CheckoutSteps";

const PaymentScreen = (props) => {
  const [paymentMethod, setpaymentMethod] = useState("");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePayment({ paymentMethod }));
    props.history.push("placeorder");
  };

  return (
    <>
      <div>
        <CheckoutSteps step1 step2 step3 />
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Payment </h2>
              </li>
              <li>
                <div>
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="paymentMethod"
                    value="stripe"
                    onChange={(e) => setpaymentMethod(e.target.value)}
                  ></input>
                  <label htmlfor="paymentMethod">Credit Card</label>
                </div>
              </li>
              <li>
                <button type="submit" className="button primary">
                  Continue
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
};
export default PaymentScreen;
