import React from "react";
import { NavLink } from "react-router-dom";

import Logo from "./FooterLogo/logoMealkit2.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="footer-container">
        <div className="footer_inner">
          <div className=" footer_logo_div col-sm-2 col-md-2 col-lg-1">
            <img src={Logo} className="footer_logo"/>
          </div>
          {/* column1 */}
          <div className="col-sm-4 col-md-4 col-lg-3 footer_detail_div">
            <h4>MEALKIT LTD</h4>
            <ul className="list-unstyled">
              <li>(852)-1234-5678</li>
              <li> 20/F-22/F, One Pacific Centre, </li>
              <li>414 Kwun Tong Road, Kwun Tong,</li>
              <li> Hong Kong</li>
            </ul>
          </div>
          {/* column2 */}
          <div className="col-sm-2 col-md-2 col-lg-3 footer_detail_div">
            <h4>About Us:</h4>
            <ul className="list-unstyled">
              <li><NavLink to="/HowWeWork" className="footerLink">How We Work</NavLink></li>
              <li><NavLink to="/Delivery" className="footerLink">Delivery</NavLink></li>
              <li><NavLink to="/specialOffer" className="footerLink">MealKit<small>+</small></NavLink></li>
            </ul>
          </div>
          {/* column3 */}
          <div className="col-sm-2 col-md-2 col-lg-3 footer_detail_div">
            <h4>Customer Support:</h4>
            <ul className="list-unstyled">
              <li><NavLink to="/helpCenter" className="footerLink">Help Center </NavLink></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row text-center">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} MEALKIT LTD. | All right reserved |
            Terms Of Service | Privacy
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
