import React ,{useState, useRef} from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import MenuChoice from "./MenuChoice";
import MenuBannerBtn from "../../MenuBannerBtn/MenuBannerBtn";

import choice1 from "../MenuBannerAsset/choice1.png";
import choice2 from "../MenuBannerAsset/choice2.png";
import choice3 from "../MenuBannerAsset/choice3.png";
import "./MenuChoiceDisplay.css";

class MenuChoiceDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headingTitle: "Explore Our Menu",
      choice: [
        {
          id: 1,
          serving: "2 serving",
          img: choice1,
          title: "Regular",
          detail:
            "Choose from an ever-changing mix of meat, fish, Beyond Meat™, WW Approved, Diabetes Friendly recipes and health-conscious offerings.",
        },
        {
          id: 2,
          serving: "2 serving",
          img: choice2,
          title: "Vegetarian",
          detail:
            "Meat-free dishes that celebrate the best of seasonal produce.",
        },
        {
          id: 3,
          serving: "2 serving",
          img: choice3,
          title: "High Protein",
          detail:
            "Choose from an ever-changing mix of meat, for your training needs.",
        },
        {
          id: 4,
          serving: "4 serving",
          img: choice3,
          title: "Meal for 4 and more ",
          detail:
            "Recipes for families, get-togethers, or left-overs, with a variety of options that change weekly, including meat, fish, Beyond Meat™ and other health-conscious offerings.",
        },
      ],
      tabType: 0,
    };
  }
  toggleTextDiv = (value) => {
    this.setState({
      tabType: value,
    });
  };
  render() {
    const ChoiceItems = this.state.choice.map((item, index) => (
      <MenuChoice
        key={item.id}
        serving={item.serving}
        img={item.img}
        title={item.title}
        detail={item.detail}
        toggleTextDiv={() => this.toggleTextDiv(index)}
        borderBottom = {this.state.tabType === index ? true : false}
      />
    ));
    return (
      <div>
        <div className="choiceDisplay">
          <h1>{this.state.headingTitle}</h1>
          <ul className="list-group list-group-horizontal ">{ChoiceItems}</ul>
          <div className="choiceDisplay__secondary">
            <p>{this.state.choice[this.state.tabType].detail}</p>
          </div>
        </div>
        <MenuBannerBtn />
      </div>
    );
  }
}

// function toggleBottomLine() {
//   const [setActive, setActiveState] = useState("0");
//   const [setLine, setLineState] = useState("2px solid #113067");
//   const [setRotate, setRotateState] = useState("accordion__icon")

//   setActiveState(setActive === "" ? "active" : "");
//   setHeightState(
//     setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
//   );
//   setRotateState(
//     setActive === 'active' ? "accordion__icon" : "accordion__icon rotate"
//   )
// }

export default MenuChoiceDisplay;

