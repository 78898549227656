import React from "react";
import MenuChoiceDisplay from "../../MenuPages/MenuBanner/MenuChoice/MenuChoiceDisplay";
import MenuWeeklyContent from "../../MenuPages/MenuPagesList/MenuWeeklyContent";

import './MenuPageScreen.css'

const MenuPageScreen = () => {
  
  return (
    <div className='menuPageScreen'>
      <MenuChoiceDisplay />
      <MenuWeeklyContent />
    </div>
  );
};
export default MenuPageScreen;
