import React from "react";

import "./OrderStep.css";
import orderOnline from "./OrderStepAsset/orderOnline.png";
import unpack from "./OrderStepAsset/unpack.png";
import cooking from "./OrderStepAsset/cooking.png";

const OrderStep = () => {
  return (
    <div className="orderStep__outta">
      <h2>To start your kit </h2>

      <div className="orderStep__inner">
        <div className="orderStep__div ">
          <figure>
            <img
              src={orderOnline}
              alt="order online img"
              className="img-thumbnail"
            />
            <h3>Choose Your Meals</h3>
          </figure>
          <div className="content">
            Our chef-designed recipes include balanced Mediterranean meals,
            quick one-pan dinners, and top-rated customer favorites.
          </div>
        </div>
        <div className="orderStep__div middle_div">
          <div className="content2">
            We guarantee the freshness of all our ingredients and deliver them
            in an insulated box right to your door.
          </div>
          <figure className="middle_div" >
            <img src={unpack} alt="unpack img" className="img-thumbnail" />
            <h3>Unpack your box</h3>
          </figure>
        </div>
        <div className="orderStep__div ">
          <figure>
            <img src={cooking} alt="cooking img" className="img-thumbnail" />
            <h3>Create magic</h3>
          </figure>
          <div className="content">
            Following our step-by-step instructions you’ll experience the magic
            of cooking recipes that our chefs create with your family’s tastes
            in mind.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStep;
