const { USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAIL, USER_FACEBOOK_REQUEST, USER_FACEBOOK_SUCCESS, USER_FACEBOOK_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_SMS_GET_REQUEST, USER_SMS_GET_SUCCESS, USER_SMS_GET_FAIL, USER_SMS_VER_REQUEST, USER_SMS_VER_SUCCESS, USER_SMS_VER_FAIL, USER_SMS_START_OVER,} = require("../Constants/userConstants");



function userSigninReducer(state = {}, action) {
    switch (action.type) {
        case USER_SIGNIN_REQUEST:
            return { loading: true };
        case USER_SIGNIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_SIGNIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default :
            return state;
    }
}

function userFacebookReducer(state = {}, action) {
    switch (action.type) {
        case USER_FACEBOOK_REQUEST:
            return { loading: true };
        case USER_FACEBOOK_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_FACEBOOK_FAIL:
            return { loading: false, error: "Fail to receive your email from Facebook." };
        case USER_LOGOUT:
            return {};
        default :
            return state;
    }
}

function userUpdateReducer(state = {}, action) {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true };
        case USER_UPDATE_SUCCESS:
            let obj = {...state, loading: false, success: true, };
            obj.userInfo = {...obj.userInfo, ...action.payload, };
            return obj;
        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {}; 
        default :
            return state;
    }
}


function userRegisterReducer(state = {}, action) {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload, success: true, };
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        default :
            return state;
    }
}

function userSMSReducer(state = {}, action) {
    switch (action.type) {
        case USER_SMS_GET_REQUEST, USER_SMS_VER_REQUEST:
            return { loading: true };
        case USER_SMS_GET_SUCCESS:
            return { loading: false, status: action.payload, };
        case USER_SMS_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_SMS_VER_FAIL:
            return { loading: false, error: "Verification Failed" };
        case USER_SMS_START_OVER:
            return { loading: false, status: null, };
        case USER_SMS_VER_SUCCESS:
            return { loading: false, status: null, success: true, };
        default :
            return state;
    }
}

function userPhoneReducer(state = {}, action) {
    switch (action.type) {
        case USER_SMS_VER_SUCCESS:
            let obj = { ...state };
            obj.userInfo.phone = action.payload.localphone;
            return obj;
        default :
            return state;
    }
}

export {userSigninReducer,
    userFacebookReducer,
    userRegisterReducer,
    userUpdateReducer,
    userSMSReducer,
    userPhoneReducer}