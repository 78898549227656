import axios from 'axios';
import Cookie from 'js-cookie';
import { USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAIL, USER_FACEBOOK_REQUEST, USER_FACEBOOK_SUCCESS, USER_FACEBOOK_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_LOGOUT, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_SMS_GET_REQUEST, USER_SMS_GET_SUCCESS, USER_SMS_GET_FAIL, USER_SMS_VER_REQUEST, USER_SMS_VER_SUCCESS, USER_SMS_VER_FAIL, USER_SMS_START_OVER, } from '../Constants/userConstants';

const signin = (email, password) => async (dispatch) => {
    dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
    try {
        const { data } = await axios.post("/api/users/signin", { email, password });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
    }
}

const facebook = (access_token) => async (dispatch) => {
    dispatch({ type: USER_FACEBOOK_REQUEST, payload: { access_token } });
    try {
        const { data } = await axios.post("/api/users/facebook", { access_token });
        dispatch({ type: USER_FACEBOOK_SUCCESS, payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: USER_FACEBOOK_FAIL, payload: error.message });
    }
}

const update = ({ userId, name, email, password, preference, allergies,tags }) => async (dispatch, getState) => {
    const { userSignin: { userInfo } } = getState()
    dispatch({ type: USER_UPDATE_REQUEST, payload: { userId, name, email, password, preference, allergies,tags } });
    try {
        const { data } = await axios.put("/api/users/" + userId,
            { name, email, password, preference, allergies,tags }, {
            headers:
                { Authorization: 'Bearer ' + userInfo.token }
        });
        dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
    }
}

const register = (name, email, password, preference, allergies) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, password, preference, allergies } });
    try {
        const { data } = await axios.post("/api/users/register", { name, email, password, preference, allergies });
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
    }
}

const generate = (phone) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_SMS_GET_REQUEST, payload: { phone } });
        const { userSignin: { userInfo } } = getState();
        const { status } = await axios.post("/api/phones/request", { phone }, {
            headers: {
                Authorization: 'Bearer ' + userInfo.token
            }
        });
        dispatch({ type: USER_SMS_GET_SUCCESS, payload: status });
    } catch (error) {
        dispatch({ type: USER_SMS_GET_FAIL, payload: error.message });
    }
}

const verify = (phone, smscode, localphone) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_SMS_VER_REQUEST, payload: { phone, smscode } });
        const { userSignin: { userInfo } } = getState();
        const { status } = await axios.post("/api/phones/check", { phone, smscode }, {
            headers: {
                Authorization: 'Bearer ' + userInfo.token
            }
        });
        dispatch({ type: USER_SMS_VER_SUCCESS, payload: { localphone, status } });
    } catch (error) {
        dispatch({ type: USER_SMS_VER_FAIL, payload: error.message });
    }
}

const restart = () => (dispatch) => {
    dispatch({ type: USER_SMS_START_OVER })
}

const logout = () => (dispatch) => {
    Cookie.remove("userInfo");
    dispatch({ type: USER_LOGOUT })
}

export { signin, facebook, register, logout, update, generate, verify, restart };