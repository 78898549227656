import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../Redux/Actions/CartActions";

function CartScreen(props) {
  const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;

  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split("=")[1])
    : 1;
  const dispatch = useDispatch();
  const removeFromCartHandler = (productId) => {
    dispatch(removeFromCart(productId));
  };
  const checkOutHandler = () => {
    props.history.push("/signin?redirect=shipping");
  };

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, []);
  return (
    <div className="cartDiv_outta">
      <div className="cartDiv_inner">
        <div className="cart-list">
          <div className="cart-list__Title">
            <h2>My Shopping Cart</h2>
          </div>
          <div className="productPicked__div">
            <ul className=" cart-list-container">
              {cartItems.length === 0 ? (
                <div className="d-flex justify-content-center">
                  Cart is empty
                </div>
              ) : (
                cartItems.map((item) => (
                  <div className="card text-center">
                    <div className="card-header">
                      <div className=" card-img-top">
                        <img
                          src={item.image}
                          alt="product"
                          className="img-thumbnail "
                        />
                      </div>
                      <div className="text-center ">
                        <div className="card-title">
                          <Link to={"/product/" + item.product}>
                            {item.name}
                          </Link>
                          <h2>$ {item.price}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="cart-price card-body"></div>
                    <div className="cartDetail_choices">
                      Qty:{" "}
                      <select
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(addToCart(item.product, e.target.value))
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
              )}
            </ul>
          </div>
        </div>
        <div className="cart-action">
          <h3>
            Subtotal: ({cartItems.reduce((a, c) => a + c.qty, 0)}items) : $
            {cartItems.reduce((a, c) => a + c.price * c.qty, 0)}
          </h3>
            { cartItems.reduce((a, c) => a + c.qty, 0) > 0 ?
            <button onClick={checkOutHandler} className="button primary full-width">Process to Checkout</button>
            : <button disabled className="button primary full-width">Process to Checkout</button>
            }
        </div>
      </div>
    </div>
  );
}

export default CartScreen;
