import { createStore, combineReducers,applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import Cookie from 'js-cookie';
import { productListReducer, productDetailsReducer, productSaveReducer, productDeleteReducer } from './Reducers/productReducer';
import { cartReducer } from './Reducers/cartReducer';

import { orderCreateReducer, OrderDetailsReducer, myOrderListReducer, OrderListReducer, orderPayReducer, orderDeleteReducer } from './Reducers/orderReducer';
import { shippingCreateReducer, shippingListReducer, shippingDeleteReducer } from './Reducers/shippingReducer';
import { userSigninReducer, userFacebookReducer, userRegisterReducer, userUpdateReducer, userSMSReducer, userPhoneReducer } from './Reducers/userReducer';
import { menuPageAccordionReducer } from './Reducers/menuPageReducer';
import reduceReducers from 'reduce-reducers';

const cartItems = Cookie.getJSON("cartItems") || [];
const userInfo = Cookie.getJSON("userInfo") ||null;

const initialState = {cart:{cartItems,shipping:{},payment:{}},userSignin:{userInfo}};
const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    cart: cartReducer,
    userSignin: reduceReducers(userSigninReducer, userFacebookReducer, userPhoneReducer),
    userRegister: userRegisterReducer,
    userUpdate: userUpdateReducer,
    userSMS: userSMSReducer,
    productSave: productSaveReducer,
    productDelete: productDeleteReducer,
    orderCreate: orderCreateReducer,
    orderDetails: OrderDetailsReducer,
    orderPay: orderPayReducer,
    myOrderList: myOrderListReducer,
    orderList: OrderListReducer,
    orderDelete: orderDeleteReducer,
    shippingCreate: shippingCreateReducer,
    shippingList: shippingListReducer,
    shippingDelete: shippingDeleteReducer,
    menuPageWeekly: menuPageAccordionReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk,logger)));
export default store;