import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { saveShipping } from '../Redux/Actions/CartActions';
import CheckoutSteps from '../Components/CheckoutSteps';
import { createShipping, listShippings, deleteShipping } from '../Redux/Actions/shippingActions';
import AreaSelect from '../Components/AreaSelect/AreaSelect';

const ShippingScreen = (props) => {
     
    const [address, setAddress] = useState('');    
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [shippingID, setShippingID] = useState('');
    const [a, setA] = useState('');  // a stores the new address
    const [b, setB] = useState('');  // b stores the new area
    const [c, setC] = useState('');  // c stores the new city
    const [d, setD] = useState('');  // d stores the new country
    const [region, setRegion] = useState('HK Island');  // d stores the new country

    const shippingCreate = useSelector(state => state.shippingCreate);
    const { loading, success, error, shipping } = shippingCreate;

    const shippingList = useSelector(state => state.shippingList);
    const { loading: loadingGet, shipping: shippingGet, error: errorGet } = shippingList;

    const shippingDelete = useSelector(state => state.shippingDelete);
    const { loading: loadingDelete, success: successDelete, error: errorDelete } = shippingDelete;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listShippings());
        return () => {
            // cleanup
        };
    }, [success, successDelete]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(saveShipping({_id: shippingID, address, area, city, country}));
        props.history.push('payment');
    }

    const placeShippingHandler = (e) => {
        e.preventDefault();
        dispatch(createShipping({address: a, area: b, city: c, country: d}));
    }

    const deleteHandler = (e, shipping) => {
        e.preventDefault();
        dispatch(deleteShipping(shipping._id));
    }

    const updateRegion = (e) => {
        setRegion(e.target.value); 
    }

    return (
        <div>
            <CheckoutSteps step1 step2 ></CheckoutSteps>
            <div className="form">
            <form onSubmit={placeShippingHandler}>
                <ul className="form-container">
                    <li>
                        <h2>{shippingGet ? "New Shipping Address?" : "Create a Shipping Address First"}</h2>
                    </li>
                    <li>{loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>{error} </div>
                    ) : <div></div>}
                    { (a && b && c && d)
                    ||(!a && !b && !c && !d)
                    ? <div></div>
                    : <div>Fill in all the fields.</div>}
                    </li>
                    <li>
                        <label for="address">
                            Address /Estate
                        </label>
                        <input type="text" name="address" id="address" onChange={(e)=>setA(e.target.value)}></input>
                    </li>
                    <li>
                        <label for="region">
                                Region 
                        </label>
                            <div className="selectmenu">
                        <select  onChange={updateRegion}>
                        <option value="HK Island">HongKong Island</option>
                        <option value="Kowloon">Kowloon</option>
                        <option value="NT">New Terrirtories</option>
                                </select>
                                </div>
                        {/* <input type="text" name="city" id="city" onChange={(e)=>setC(e.target.value)}></input> */}
                        </li>
                        <li>
                        <label for="area">
                                Area
                        </label>
                           
                        <input type="text" name="area" id="area" onChange={(e)=>setB(e.target.value)}></input>
                        </li>
                        <AreaSelect region={region}/>
                    {/* <li>
                        <label for="country">
                            Country 
                        </label>
                        <input type="text" name="country" id="country" onChange={(e)=>setD(e.target.value)}></input>
                    </li> */}
                    <li>
                        {
                        a && b && c && d ?
                        <button type="submit" className="button primary">Create</button>:
                        <button disabled type="submit" className="button primary">Create</button>
                        }
                    </li>
                </ul>
            </form>
            <form onSubmit={submitHandler}>
                <ul className="form-container">
                    <li>
                        <h2>Saved Address(es)</h2>
                    </li>
                    <li>{success ? (<div>New Address Received</div>) :
                    loadingDelete ? (<div>Loading...</div>) :
                    errorDelete ? (<div>Fail to Delete Address</div>) :
                    successDelete ? (<div>Address Removed</div>) : <></>}
                    </li>
                    <li>
                    <div>
                        {shippingGet ? shippingGet.map( (shipping, index) => <div>
                        <input type="radio" id={index} name="address" value={shipping._id}
                        onClick={()=>{
                            setShippingID(shipping._id);
                            setAddress(shipping.address);
                            setArea(shipping.area);
                            setCity(shipping.city);
                            setCountry(shipping.country);
                            }}/>
                        <label for={index} className="shipping">
                        <div>{shipping.address},</div>
                        <div>{shipping.area},</div>
                        <div>{shipping.city},</div>
                        <div>{shipping.country}</div></label>
                        <button onClick={(e)=>deleteHandler(e, shipping)}>Delete</button>
                        </div>):''}
                    </div>
                    </li>
                    <li>
                        {
                        shippingID ?
                        <button type="submit" className="button primary">Continue</button>:
                        <div><button disabled type="submit" className="button primary">Continue</button></div>
                        }
                    </li>
                </ul>
            </form>
        </div>
        </div>
        
    )}
export default ShippingScreen;