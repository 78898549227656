import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { register } from '../Redux/Actions/userActions';

const RegisterScreen = (props) => {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [preference, setPreference] = useState('');
    const [allergies,setAlleregies] = useState('');
    const userRegister = useSelector(state => state.userRegister);
    const { loading, userInfo, error, success, } = userRegister;
    const dispatch = useDispatch();
    const [lengthchecking, setLengthChecking] = useState(false);
    const [repasschecking, setRepassChecking] = useState(false);

    const redirect = props.location.search ? props.location.search.split("=")[1] : '/shop';
    useEffect(() => {
        if (userInfo || success) {
            props.history.push(redirect);
        }
        return () => {
            // cleanup
        };
    }, [success])


    const submitHandler = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setLengthChecking(true);
        } else if (password != rePassword ) {
            setRepassChecking(true);
        }
        else{
            setLengthChecking(false);
            setRepassChecking(false);
            dispatch(register(name, email, password,preference,allergies));
        }
    }

    return (
        <div className="form">
            <form onSubmit={submitHandler}>
                <ul className="form-container">
                    <li>
                        <h2>Create ACCOUNT!</h2>
                    </li>
                    <li>
                        {loading && <div>Loading...</div>}
                        {error && <div>{error}</div>}
                        {success && <div>Profile Created Success</div>}
                        {lengthchecking && <div>Password needs to be more than 8 character</div>}
                        {repasschecking && <div>Password does not match, please retype</div>}
                    </li>
                    <li>
                        <label for="name">
                            Name 
                        </label>
                        <input type="name" name="name" id="name" onChange={(e)=>setName(e.target.value)}></input>
                    </li>
                    <li>
                        <label for="email">
                            Email 
                        </label>
                        <input type="email" name="email" id="email" onChange={(e)=>setEmail(e.target.value)}></input>
                    </li>
                    <li>
                        <label for="password">
                            Password
                        </label>
                        <input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}>
                        </input> 
                    </li>
                    <li>
                        <label for="rePassword">
                            Re enter-Password
                        </label>
                        <input type="password" name="rePassword" id="rePassword" onChange={(e) => setRePassword(e.target.value)}>
                        </input> 
                    </li>
                    <div className="Preference">
                    <li>
                            <h2>Preference And Allegeries</h2>
                        </li>
                        <li>
                            <label htmlfor="preference">
                                Preference
                        </label>
                            <input value={preference} type="preference" name="preference" id="preference" onChange={(e) => setPreference(e.target.value)}>
                            </input>
                        </li>
                        <li>
                            <label htmlfor="allergies">
                                Allergies
                        </label>
                            <input value={allergies} type="allergies" name="allergies" id="allergies" onChange={(e) => setAlleregies(e.target.value)}>
                            </input>
                        </li></div>
                <li>
                    
                        <button type="submit" className="button primary">Register</button>
                    </li>
                    <li>
                        Already have an account?
                        <Link to={redirect === "/" ? "signin" : "signin?redirect="+ redirect} className="button secondary text-center">Create account now!</Link>
                   
                    </li>
          
                </ul>

            </form>
        </div>
    )}
export default RegisterScreen;