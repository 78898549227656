import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

//above to change pages by using those components from above

// import from '../../screens/HomePageScreen/';
import HomePageScreen from "../../screens/HomePageScreen/HomePageScreen";
import MenuPageScreen from "../../screens/MenuPageScreen/MenuPageScreen";
// above we will need to import other page for Menu, Pricing, SignIn pages

import "./SideDrawer.css";
// above： styling

const SideDrawer = (props) => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }else{
    drawerClasses = "side-drawer close"
  }

  return (
    <nav className={drawerClasses}>
      <ul>
        <li className="noShow">
          <NavLink onClick={props.close} to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink onClick={props.close} to="/OnTheMenu">
            On The Menu
          </NavLink>
        </li>
        <li>
          <NavLink onClick={props.close} to="/shop">
            Pricing
          </NavLink>
        </li>
        <li>
          <NavLink onClick={props.close} to="/cart">
            Cart
          </NavLink>
        </li>
        <li>
          {userInfo ? (
            <NavLink onClick={props.close} to="/profile">
              {userInfo.name}
            </NavLink>
          ) : (
            <NavLink onClick={props.close} to="/signin">
              Sign In
            </NavLink>
          )}
        </li>

        <li>
          {userInfo && userInfo.isAdmin && (
            <div className="child-content">
              
                <li>
                  <NavLink onClick={props.close} to="/orders">
                    Orders
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={props.close} to="/products">
                    Products
                  </NavLink>
                </li>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};
export default SideDrawer;
