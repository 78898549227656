import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { listMyOrders } from '../Redux/Actions/OrderActions'

import { logout, update } from '../Redux/Actions/userActions'

import Tagsinput from "../Components/Tagsinput/Tagsinput";


function ProfileScreen(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('')
    const [preference, setPreference] = useState('');
    const [allergies, setAlleregies] = useState('');
    const [tags, setTags] = useState('');
    const [facebookid, setFacebookid] = useState('');
    const dispatch = useDispatch();
    const [lengthchecking, setLengthChecking] = useState(false);
    const [repasschecking, setRepassChecking] = useState(false);


    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    const handleLogOut = () => {
        dispatch(logout());
        props.history.push("/signin")
    }
    const submitHandler = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setLengthChecking(true);
        } else if (password != rePassword) {
            setRepassChecking(true);
        }
        else {
            setLengthChecking(false);
            setRepassChecking(false);
            dispatch(update({ userId: userInfo._id, email, name, password, preference, allergies, tags }))
        }
    }

    const userUpdate = useSelector(state => state.userUpdate);
    const { loading, success, error } = userUpdate


    const myOrderList = useSelector(state => state.myOrderList);
    const { loading: loadingOrders, orders, error: errorOrders } = myOrderList

    useEffect(() => {
        if (userInfo) {
            console.log(userInfo.name)
            setEmail(userInfo.email);
            setName(userInfo.name);
            setPassword(userInfo.password);
            setFacebookid(userInfo.facebook_id);
        }
        dispatch(listMyOrders());
        return () => {

        }
    }, [])

    return <div className="profile">
        <div className="profile-info">
            <div className="form">
                <form onSubmit={submitHandler}>
                    <ul className="form-container">
                        <li>
                            <h2>User Profile</h2>
                        </li>
                        <li>
                            {loading && <div>Loading...</div>}
                            {error && <div>{error}</div>}
                            {success && <div>Updated. Please logout and login again to apply new setting.</div>}
                            {lengthchecking && <div>Password needs to be more than 8 character</div>}
                        {repasschecking && <div>Password does not match, please retype</div>}
                        </li>
                        <li>
                            <label htmlfor="name">
                                Name
                        </label>
                            {facebookid ? <input value={name} readOnly /> :
                                <input value={name} type="name" name="name" id="name" onChange={(e) => setName(e.target.value)}></input>
                            }
                        </li>
                        <li>
                            <label htmlfor="email">
                                Email
                        </label>
                            {facebookid ? <input value={email} readOnly /> :
                                <input value={email} type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}></input>
                            }
                        </li>
                        {facebookid ? <li /> :
                            <li>
                                <label htmlfor="password">
                                    Password
                        </label>
                                <input value={password} type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}>
                                </input>
                                <label htmlfor="password">
                                   Re-Enter Password
                        </label>
                                <input type="password" name="rePassword" id="rePassword" onChange={(e) => setRePassword(e.target.value)}/>
                            </li>
                           
                        }
                        <div className="Preference">
                            <li>
                                <h2>Preference And Allegeries</h2>
                            </li>
                            <li>
                                <label htmlfor="preference">
                                    Preference
                                 </label>
                                <input value={preference} type="preference" name="preference" id="preference" onChange={(e) => setPreference(e.target.value)}>
                                </input>
                            </li>
                            <li>
                                <label htmlfor="allergies">
                                    Allergies
                                </label>
                                <input value={allergies} type="allergies" name="allergies" id="allergies" onChange={(e) => setAlleregies(e.target.value)}>
                                </input>
                            </li>
                            <Tagsinput inputconfirm={(e) => setTags(e)}/>
                        </div>

                        <li>
                            <li>
                                <NavLink to="/verify">Click here to Check / Add Your Mobile Phone</NavLink>
                            </li>
                            <div className="preference"></div>
                            {facebookid ? <li>You are currently logged in as a Facebook User</li> : <li />}
                            {facebookid ?
                                <button disabled type="submit" className="button primary">Update</button>
                                : <button type="submit" className="button primary">Update</button>
                            }
                        </li>
                        <li>
                            <button type="button" onClick={handleLogOut} className="button secondary full-width">LogOut</button>
                        </li>

                    </ul>

                </form>
            </div>
        </div>
        <div className="profile-orders content-margin">
            {
                loadingOrders ? <div>Loading...</div> :
                    errorOrders ? <div>{errorOrders}</div> :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>DATE</th>
                                    <th>TOTAL</th>
                                    <th>PAID</th>
                                    <th>ACTIONS</th>

                                </tr>
                            </thead>
                            <tbody>
                                {orders ? orders.map(order => <tr key={order._id}>
                                    <td>{order._id}</td>
                                    <td>{order.createdAt}</td>
                                    <td>{order.totalPrice}</td>
                                    <td>{order.isPaid.toString()}</td>
                                    <td><Link to={"/order/" + order._id}>DETAILS</Link></td>
                                </tr>) : <tr />}
                            </tbody>
                        </table>
            }
        </div>
    </div>


}
export default ProfileScreen;
