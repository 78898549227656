import React, { useState } from "react";
import { NavLink } from "react-router-dom";
//above to change pages by using those components from above

import SideDrawer from "../SideDrawer/SideDrawer";
import Backdrop from "../Backdrop/Backdrop";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
// above for toolbar component

import { useSelector } from "react-redux";
import Category from "../Category/Category";

import HomePageScreen from "../../screens/HomePageScreen/HomePageScreen";
import MenuPageScreen from "../../screens/MenuPageScreen/MenuPageScreen";
// above Main pages's componest

// import FooterLink_helpCenter from "../../HomePageComponent/Footer/Footer_Links/FooterLink_HelpCenter/HelpCenter_innerContent"
// import FooterLink_specialOffer from "../../HomePageComponent/Footer/Footer_Links/FooterLink_specialOffer";
// import FooterLink_delivery from "../../HomePageComponent/Footer/Footer_Links/FooterLink_delivery";
// import FooterLink_aboutUs from "../../HomePageComponent/Footer/Footer_Links/FooterLink_aboutUs";
// import Footer from "../../HomePageComponent/Footer/Footer";
// above Footer pages's componest


import WhatApp from "./ToolbarAsset/whatApp";
import mealkitLogo from "./ToolbarAsset/logoMealkit.svg";
import "./Toolbar.css";
// above : styling & images

const Toolbar = (props) => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    if (sideDrawerOpen) {
      setSideDrawerOpen(false);
    } else {
      setSideDrawerOpen(true);
    }
  };
  
  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };
  const [backdrop, setBackdrop] = useState();

  return (
    <div>
      <header className="toolbar">
        <nav className="toolbar__nav">
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={drawerToggleClickHandler} />
          </div>
          <div className="toolbar__logo">
            <NavLink to="/">
              <img
                src={mealkitLogo}
                alt="brand logo"
                className="toolbar__Logo"
              />
              <p>
                meal<span>kit</span>
              </p>
            </NavLink>
          </div>
          <div className="spacer"></div>
          <div className="toolbar_nav-items">
          <ul>
            <li>
              <NavLink to="/OnTheMenu">On The Menu</NavLink>
            </li>
            <li>
              <NavLink to="/shop">Pricing</NavLink>
            </li>
            <li>
              <NavLink to="/cart">Cart</NavLink>
            </li>
            <li>
              {userInfo ? (
                <NavLink to="/profile">{userInfo.name}</NavLink>
              ) : (
                  <NavLink to="/signin">Sign In</NavLink>)
              }
            </li>
            <li>
              {userInfo && userInfo.isAdmin && (
                <div className="dropdown">
                  <span>Admin:</span>
                  <NavLink to="/orders">Orders</NavLink>
                  <NavLink to="/products">Products</NavLink>
                </div>
              )}
            </li>
          </ul>
        </div>
          <div className="toolbar_nav-item">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=85251042075"
            >
              <WhatApp
                className={"whatappIcon"}
                width={40}
                fill={"#059105"}
              />{" "}
            </a>
          </div>
        </nav>
      </header>
      <SideDrawer show={sideDrawerOpen} close={drawerToggleClickHandler} />
        {backdrop}
        {sideDrawerOpen ? <Backdrop click={backdropClickHandler} /> : <></>}
    </div>
  );
};

export default Toolbar;
