// HardCode1: content : about why choosing MealKit

import React from "react";

import Leaf from "./iconLeaf";
import Heart from "./iconHeart";
import Meal from "./iconMeal";
// svg icons, use it like {meal}

import "./whyUs.css";

const WhyUs = () => {
  return (
    <div className="whyUs__outta">
      <div className="whyUs__title">
        <h2>Why MEALKIT</h2>
      </div>
      <div className="whyUs__content ">
        <div className="col-sm-12 col-md-4 col-lg-4 whyus__Ans1">
          <i style={{ color: "#F36127" }}>
            <Leaf width="150px" height="auto" />
          </i>
          <p>
            Provide all in one environmental friendly solution that prepare for
            all the meals ingredient.
          </p>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 whyus__Ans2">
          <i style={{ color: "#F36127" }}>
            <Heart width="150px" height="auto" />
          </i>
          <p>
            No matter that there’s a particular dishes that you want to cook.
          </p>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 whyus__Ans3">
          <i style={{ color: "#F36127" }}>
            <Meal width="150px" height="auto" />
          </i>
          <p>
            Our recipe have the easiest way for you, make your meal happier in
            every time
          </p>
        </div>
      </div>
    </div>
  );
};
export default WhyUs;
