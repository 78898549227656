import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { createOrder } from "../Redux/Actions/OrderActions";
import CheckoutSteps from "../Components/CheckoutSteps";

function PlaceOrderScreen(props) {
  const cart = useSelector((state) => state.cart);
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;

  const { cartItems, shipping, payment } = cart;
  if (!shipping.address) {
    props.history.push("/shipping");
  } else if (!payment.paymentMethod) {
    props.history.push("/payment");
  }

  const itemsPrice = cartItems.reduce((a, c) => a + c.price * c.qty, 0);
  const shippingPrice = itemsPrice >= 500 ? 0 : itemsPrice >= 200 ? 20 : 60;
  // const taxPrice = 0.15 * itemsPrice;
  // const totalPrice = itemsPrice + shippingPrice + taxPrice;
  const totalPrice = itemsPrice + shippingPrice;

  const dispatch = useDispatch();
  //createOrder
  const placeOrderHandler = () => {
    // dispatch(createOrder({ cartItems, shipping, payment, itemsPrice, taxPrice, shippingPrice, totalPrice }));
    dispatch(
      createOrder({
        orderItems: cartItems,
        shipping_id: cart.shipping._id,
        payment,
        itemsPrice,
        shippingPrice,
        totalPrice,
      })
    );
  };

  useEffect(() => {
    if (success) {
      props.history.push("/order/" + order._id);
    }
  }, [success]);

  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
      <div className="placeorder">
        <div className="placeorder-info">
          <div>
            <h3>Shipping</h3>
            <div>
              {cart.shipping.address}, {cart.shipping.area},{cart.shipping.city}
              ,{cart.shipping.country},
            </div>
          </div>
          <div>
            <h3>Payment</h3>
            <div>Payment Method: {cart.payment.paymentMethod}</div>
          </div>
          <div>
            <div className="productPicked__div ">
              <div>
                <h3>Shopping Cart</h3>
              </div>
              <ul className="cart-list-container">
                {cartItems.length === 0 ? (
                  <div>Cart is empty</div>
                ) : (
                  cartItems.map((item) => (
                    <div className="card text-center">
                      <div className="card-harder">
                        <div className=" card-img-top">
                          <img
                            src={item.image}
                            alt="product"
                            className="img-thumbnail "
                          />
                        </div>
                        <div className="text-center ">
                          <div className="card-title">
                            <Link to={"/product/" + item.product}>
                              {item.name}
                            </Link>
                            <h2>$ {item.price}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="placeorder-action">
        <ul>
          <li>
            <h3>Order Summary</h3>
          </li>
          <li>
            <div>Items</div>
            <div>${itemsPrice}</div>
          </li>
          <li>
            <div>Shipping</div>
            <div>${shippingPrice}</div>
          </li>
          {/* <li>
                            <div>Tax</div>
                            <div>${taxPrice}</div>
                        </li> */}
          <li>
            <div>Order Total</div>
            <div>${totalPrice}</div>
          </li>
          <li>
            <button
              className="button primary full-width"
              onClick={placeOrderHandler}
            >
              PlaceOrder
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PlaceOrderScreen;
