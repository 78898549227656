import React from 'react';
import recipeImg from './recipeImg';

const recipeHeading =(props)=>{
    return (
        <h2>{props.name}</h2>
    )
}
export default recipeHeading;

// Greek-Style Veggie Pitas