
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { listProducts } from "../Redux/Actions/productActions";

import "./HomeScreen.css";

const HomeScreen = (props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const category = props.match.params.id ? props.match.params.id : "";
  const productList = useSelector((state) => state.productList);
  const { products, loading, error } = productList;
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(listProducts(category));
    // const fetchData = async () => {
    //     // const data = await axios.get("http://127.0.0.1:5000/api/products");
    //     // console.log(data)
    //     const { data } = await axios.get("http://127.0.0.1:5000/api/products");
    //     setProduct(data);
    // }
    // fetchData();
    return () => {};
  }, [category]);


  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(listProducts(category, searchKeyword, sortOrder));
  };
  const sortHandler = (e) => {
    setSortOrder(e.target.value);
    dispatch(listProducts(category, searchKeyword, sortOrder));
  };


  return (
    <div className="category_product__outta">
      <div className="category_product__inner">
        <filter className="filter_div_outta ">
          <Form
            onSubmit={submitHandler}
            className="filter_search_btn  "
          >
            <Form.Label className="filterInput_label">
              Search Product{" "}
            </Form.Label>
            <Form.Control
              size="lg"
              className="filter_inputFeild"
              placeholder="Input product name"
              type="text"
              name="searchKeyword"
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </Form>
          <div className="filter_search_btn ">
            <Form.Label className="filterInput_label"> Sort By </Form.Label>
            <Form.Control
              size="lg"
              as="select"
              name="sortOrder"
              onChange={sortHandler}
              className="filter_inputFeild"
            >
              <option>Newest</option>
              <option>Lowest</option>
              <option>Highest</option>
            </Form.Control><Button variant="primary" type="submit" >
            Search
          </Button>
          </div>{" "}
          
        </filter>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <ul className="show_products">
            {products.map((product) => (
              <li key={product._id}>
                <div className="product">
                  <Link to={"/product/" + product._id}>
                    <img
                      className="product-image img-thumbnail"
                      src={product.image}
                      alt="product"
                    />
                  </Link>
                  <div className="product-name">
                    <Link to={"/product/" + product._id}>{product.name}</Link>
                  </div>
                  <div className="product-brand">{product.brand}</div>
                  <div className="product-price">{product.price}</div>
                  {/* <div className="product-price">Standard Price:{product.price}</div>
                                <div className="product-bprice">Bundle Price:{product.bprice}</div> */}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default HomeScreen;

