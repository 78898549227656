import React from 'react';
import axios from 'axios';
import Select from 'react-select';

class AreaSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOptions: [],
            area: ''
        }
    }

  
    async getOptions(str) {
        const res = await axios.get(`/api/shippings/area?region=${str}`);
    
        const options = res.data.map(d => ({
            "label": d
        }))
        this.setState({ selectOptions: options })
    }

    componentDidMount() {
       
        this.getOptions(this.props.region);
    }
    componentDidUpdate(prevProps) {
        if(this.props.region !== prevProps.region){
    
        this.getOptions(this.props.region);}
    }

    handleChange(e) {
         this.setState({id:e.value, area:e.label})
    }


    render() {
        // console.log(this.state.selectOptions)
        return (
            
            <div className="selectmenu"> 
                 <Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />
            </div>
        )
    }
}

export default AreaSelect