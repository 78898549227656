import React from "react";

function CheckoutSteps(props) {
  return (
      <div className="payment_outta">
    <div className="checkout-steps ">
      <div className={props.step1 ? "active" : ""}>Sign-in</div>
      <div className={props.step2 ? "active" : ""}>Shipping</div>
      <div className={props.step3 ? "active" : ""}>Payment</div>
      <div className={props.step4 ? "active" : ""}>Place Order</div>
    </div></div>
  );
}

export default CheckoutSteps;
