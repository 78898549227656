export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_FACEBOOK_REQUEST = 'USER_FACEBOOK_REQUEST';
export const USER_FACEBOOK_SUCCESS = 'USER_FACEBOOK_SUCCESS';
export const USER_FACEBOOK_FAIL = 'USER_FACEBOOK_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_SMS_GET_REQUEST = 'USER_SMS_GET_REQUEST';
export const USER_SMS_GET_SUCCESS = 'USER_SMS_GET_SUCCESS';
export const USER_SMS_GET_FAIL = 'USER_SMS_GET_FAIL';
export const USER_SMS_VER_REQUEST = 'USER_SMS_VER_REQUEST';
export const USER_SMS_VER_SUCCESS = 'USER_SMS_VER_SUCCESS';
export const USER_SMS_VER_FAIL = 'USER_SMS_VER_FAIL';
export const USER_SMS_START_OVER = 'USER_SMS_START_OVER';