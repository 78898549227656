import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { savePayment } from "../Redux/Actions/CartActions";
import CheckoutSteps from "../Components/CheckoutSteps";
import { detailsOrder, payOrder } from "../Redux/Actions/OrderActions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import stripeAPI from "../StripeAPI";
import PhoneScreen from "./PhoneScreen";
import axios from "axios";

import StripeForm from "../Components/StripeForm";
const stripePromise = stripeAPI
  .getPublicStripeKey()
  .then((key) => loadStripe(key));

const OrderScreen = (props) => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    success: successPay,
    error: errorPay,
  } = orderPay;
  const dispatch = useDispatch();
  useEffect(() => {
    //   if (successPay) {
    //     props.history.push("/profile");
    //   } else {
    dispatch(detailsOrder(props.match.params.id));
    //   }
      return () => {
      };
    }, [successPay]);
  
    const handleSuccessPayment = (paymentResult) => {
      dispatch(payOrder(order, paymentResult));
      let followUpMail = {
          name: userInfo.name,
          lastname: "Meal Kit",
          email: userInfo.email,
          message: `A new order is paid and please handle as soon as possible.<br/>
          Customer: ${userInfo.name}<br/>
          Phone number: ${userInfo.phone}<br/>
          Order number: ${order._id}`,
      };
      axios.post('/api/forma', followUpMail);
    }

    const orderDetails = useSelector(state => state.orderDetails);
    const { loading, order, error } = orderDetails;
    if (order) {order.paidAt = new Date(order.paidAt).toLocaleString();
        order.deliveredAt = new Date(order.deliveredAt).toLocaleString();};

  return loading ? (
    <div>Loading ...</div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="orderScreen__div">
      <div className="placeorder">
        <div className="placeorder-info">
          <div>
            <h3>Shipping</h3>
            <div>
              {order.shipping.address}, {order.shipping.area},
              {order.shipping.city},{order.shipping.country},
            </div>
            <div>
              {order.isDelivered
                ? "Delivered at" + order.deliveredAt
                : "Not Delivered."}
            </div>
          </div>
          <div>
            <h3>Payment</h3>
            <div>Payment Method: {order.payment.paymentMethod}</div>
            <div>{order.isPaid ? "Paid at" + order.paidAt : "Not Paid."}</div>
          </div>
          <div className="productPicked__div">
            <div>
              <h3>Shopping Cart</h3>
            </div>
            <ul className="cart-list-container ">
              {order.orderItems.length === 0 ? (
                <div>Cart is empty</div>
              ) : (
                order.orderItems.map((item) => (
                  <div className="card text-center">
                    <div className=" card-img-top">
                      <img
                        src={item.image}
                        alt="product"
                        className="img-thumbnail "
                      />
                    </div>
                    <div className="text-center ">
                          <div className="card-title">
                        <Link to={"/product/" + item.product}>{item.name}</Link>
                      </div>
                      <h2>Qty:{item.qty}</h2>
                    
                    <h2>$ {item.price}</h2></div>
                  </div>
                ))
              )}
            </ul>
          </div>
        </div>
        </div>
        <div className="placeorder-action">
          <ul>
            {order.isPaid ? (
              ""
            ) : userInfo.phone ? (
              <li className="placeorder-actions-payment">
                {loadingPay && <div>Finishing Payment...</div>}
                {!order.isPaid && (
                  <Elements stripe={stripePromise}>
                    <StripeForm
                      amount={order.totalPrice}
                      currency="HKD"
                      onSuccess={handleSuccessPayment}
                    />
                  </Elements>
                )}
              </li>
            ) : (
              <>
                <div>
                  <h5>Verify your phone number first before you pay</h5>
                </div>
                <PhoneScreen />
              </>
            )}
            <li>
              <h3>Order Summary</h3>
            </li>
            <li>
              <div>Items</div>
              <div>${order.itemsPrice}</div>
            </li>
            <li>
              <div>Shipping</div>
              <div>${order.shippingPrice}</div>
            </li>
            {/* <li>
                            <div>Tax</div>
                            <div>${order.taxPrice}</div>
                        </li> */}
            <li>
              <div>Order Total</div>
              <div>${order.totalPrice}</div>
            </li>
          </ul>
        </div>
      
    </div>
  );
};
export default OrderScreen;
